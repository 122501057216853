import { lazy, Suspense, useEffect } from 'react'
import ReactDOM from 'react-dom'
import './assets/scss/custom.css'
import './assets/scss/style.scss'

import Spinner from './views/spinner/Spinner'

const App = lazy(
    () =>
        new Promise((resolve) => {
            setTimeout(() => resolve(import('./app')), 0)
        }),
)

const MicrosoftClarityScript = () => {
    useEffect(() => {
        // Código do Microsoft Clarity
        ;(function (c, l, a, r, i, t, y) {
            c[a] =
                c[a] ||
                function () {
                    ;(c[a].q = c[a].q || []).push(arguments)
                }
            t = l.createElement(r)
            t.async = 1
            t.src = 'https://www.clarity.ms/tag/' + i
            y = l.getElementsByTagName(r)[0]
            y.parentNode.insertBefore(t, y)
        })(window, document, 'clarity', 'script', 'ofixda8let')
    }, [])

    return null
}

ReactDOM.render(
    <Suspense fallback={<Spinner />}>
        <MicrosoftClarityScript />
        <App />
    </Suspense>,
    document.getElementById('root'),
)
